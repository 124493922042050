.loading-div{
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    
    img{
        display: flex;
        border-radius: 1rem;
    }

    .loading-progress{
        flex-grow: 1;
        
    }
}

.main-loading-div{
    position: fixed;
    justify-content: center;
    display: flex;
    background-color: white;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999;
    
    .loading-progress{
        flex-grow: 1;
        
    }
}