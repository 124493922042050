@import '../../abstract/common';
@import "../../abstract//variables";

@mixin basic-button {
    min-width: 70px;
    border-radius: 9999px;
    @include padding;
}

.login-button {
    @include flex-center;
    @include basic-button;
    background-color: rgb(239,239,239)!important;
    color: black;
    font-weight: 600;
    cursor: pointer;
    margin-right: 8px;
}

.signup-button{
    @include flex-center;
    @include basic-button;
    background-color: red!important;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.babe-button{
    @include flex-center;
    @include basic-button;
    background: #000;// linear-gradient(180deg, #f92035 0, #f92035 10%, #ffcf36 10%, #ffcf36 20%, #f96f34 20%, #f96f34 80%, #ffcf36 80%, #ffcf36 90%, #40ccff 90%,#40ccff 100%) ;
    color: white;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
}