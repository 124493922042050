/* @import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"); */
@import '../scss/abstract/common';
@import '../scss/abstract/variables';

html,
body,
div,
span,
applet,
object,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
p,
figcaption {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
 
}

p {
  line-height: 21px;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

* {
  box-sizing: border-box;
}



a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
 
}

.justify-center{
  justify-content: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page {
  @include float-y-wrapper;
}

img{
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.flex-gap {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 10px solid transparent;
}

.flex-gap-1 {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 10px solid transparent;
}


.horizontal-scroll::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
} 

.rentb-background{
  background: $rentb-background;
  
}

.chat-background{
  background: $background-image;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

div, img {
  -webkit-tap-highlight-color:transparent;
}

body{
  overflow-x: hidden;
}